import * as React from 'react'
import Layout from '../components/Layout'
import Map from '../components/Map/Map'
import { Button } from '../components/Button'
import {
  Text,
  Cards,
  Container,
  SplitContent,
  SplitSection,
  SplitFigure,
  ReadMore
} from '../components/styles'
import styled from 'styled-components'
import { xPad, yPad } from '../utils/theme'
import Contact from '../components/Contact'
import Card from '../components/Card'
import Img from 'gatsby-image'
import { graphql, Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import Arrow from '../components/Arrow'
import CTA from '../components/CTA'
import { Flex, Box } from 'grid-styled'
import Hero from '../components/Hero'
import ContentCard from '../components/ContentCard'
import BackgroundSection from '../components/BackgroundSection'
import CTACard from '../components/CTACard'

const Images = styled.div`
  display: flex;
  margin: 30px 0 50px;
  justify-content: center;
  img,
  .gatsby-image-outer-wrapper {
    width: 250px;
    height: auto;
  }

  @media (max-width: 450px) {
    flex-direction: column;
    align-items: center;
  }
`

const VideoContainer = styled.section`
  width: 100%;
  overflow: hidden;
  margin: 80px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h2 {
    font-weight: 400;
  }

  article {
    margin-top: 30px;
    width: 80%;
    max-width: 840px;
    color: #333;
    text-align: left;

    i {
      font-size: 1.5em;
      font-style: normal;
      color: ${props => props.theme.colors.primary};
    }
  }
`

const HomeHeader = styled.header`
  height: 80vh;
  padding-top: 7rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  color: #fff;
  position: relative;

  display: flex;
  justify-content: space-around;
  align-items: flex-start;

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 8rem;
    height: 650px;
    > div > button {
      display: none;
    }
    > div:nth-child(2) {
      margin-top: 4rem;
    }
  }
  @media (max-width: 480px) {
    > div:nth-child(2) {
      margin-top: 10rem;
    }
  }

  figure {
    background-image: linear-gradient(-45deg, #fe9124 0%, #e71506 100%);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transform: skewY(-8deg);
    transform-origin: left top 0px;

    .gatsby-image-outer-wrapper {
      position: absolute !important;
      width: 85vw;
      bottom: -50px;
      right: 0;
    }
  }

  h2 {
    color: #fff;
    font-weight: 500;
    max-width: 700px;

    em {
      text-decoration: underline;
      font-weight: 700;
      font-style: normal;
    }
  }

  p {
    font-weight: 500;
    line-height: 150%;
    padding-right: 2rem;
    max-width: 500px;
    margin: 1.5rem 0 2rem;
    opacity: 0.95;

    @media (max-width: 900px) {
      padding-right: 0;
    }
  }

  @media (min-width: 600px) {
    padding-top: 9rem;
    /* padding-left: 5rem; */
  }

  @media (min-width: 940px) {
    /* padding-left: 10rem; */
  }
`

const ServicesContainer = styled(Container)`
  > div {
    margin-bottom: 6rem;
  }
`
const CardsContainer = styled(Container)`
  > div {
    justify-content: space-around;
    @media (min-width: 940px) {
      > div {
        width: 31%;
      }
    }
  }
`

const AlternatingContent = styled.section`
  overflow: hidden;
  padding: 50px 0;
`

const StyledBackgroundSection = styled(BackgroundSection)`
  min-height: 600px;
  @media (max-width: 500px) {
    height: auto;
    padding-bottom: 3rem;
  }
`

export const AboutContact = ({ data, noContact, noBorder }) => (
  <React.Fragment>
    <StyledBackgroundSection
      darkness="0.3"
      image={data.drSellars.childImageSharp}
      title={'About Rob'}
    >
      <p>
        Rob is a psychologist with over 11 years’ experience in providing
        Disability and Mental Health support to children, adolescents, and
        adults. He uses tailored approaches for all of his clients with
        evidence-based interventions. He partners with individuals, families and
        support teams for short- or long-term interventions.
      </p>

      <Link to="/about">
        <Button outline={true} color="#fff" small={true}>
          Read more
        </Button>
      </Link>
    </StyledBackgroundSection>

    {!noContact && <Contact noBorder={noBorder} />}
  </React.Fragment>
)

/* prettier-ignore */
interface IProps {
  data: {
    womanWaterfall: {
      childImageSharp: {
        fluid: object
      }
    };
    woodsWoman: {
      childImageSharp: {
        fluid: object
      }
    };
    waterBodyWoman: {
      childImageSharp: {
        fluid: object
      }
    };
    faceCloseUp: {
      childImageSharp: {
        fluid: object
      }
    };
    drSellars: {
      childImageSharp: {
        fluid: object
      }
    };
    nonSurgical: {
      childImageSharp: {
        fluid: object
      }
    };
  }
}

export default ({ data }: IProps) => {
  // if (!data) {
  //   console.log('Error: no data found')
  //   return null
  // }

  return (
    <Layout header="light">
      <Helmet>
        <title>
          Upright Performance - Mental Health &amp; Disability Services -
          Psychologist Brisbane & Gold Coast
        </title>
        <meta
          name="description"
          content="Upright Performance aims to give you a genuinely supportive space where you are cared for, can truly be yourself, and heal. We use evidence based techniques to create a tailored path for your needs."
        />
      </Helmet>

      <Hero
        bgImage={data.womanWaterfall.childImageSharp}
        height="600px"
        noForm={true}
        color={'#fff'}
      >
        <span
          style={{
            fontSize: 22,
            color: '#fff',
            fontWeight: 600,
            marginBottom: 0
          }}
        >
          Rob Morgan
        </span>
        <h1 style={{ marginTop: 0 }}>
          Psychology Services
          <br /> Brisbane & Gold Coast
        </h1>
        <p>
          Trusted and experienced Disability and Mental Health services in the
          greater Brisbane & Gold Coast regions.
        </p>
        <Flex>
          <Link to="/contact">
            <Button color={'#fff'} small={true} outline={true}>
              Contact Rob Today
            </Button>
          </Link>
        </Flex>
      </Hero>


      <div
        style={{
          border: '2px solid #003C5D',
          borderRadius: '8px',
          padding: '30px',
          width: 1200,
          maxWidth: '90%',
          margin: '0 auto',
          marginTop: '30px',
          fontSize: '1.25em',
          marginBottom: '-50px',
          textAlign: 'center',
          backgroundColor: 'rgba(0, 60, 93, 0.1)'
        }}>
        <p>
          <i>
            <strong style={{ color: '#003C5D' }}>
              We’re now offering services in the Gold Coast. Limited referrals available.
            </strong>
          </i>
        </p>
      </div>

      <AlternatingContent>
        <ContentCard
          image={data.woodsWoman.childImageSharp}
          title="Psychology Services"
          text="Rob aims to give you a genuinely supportive space where you are cared for, can truly be yourself, and heal. Rob uses evidence based techniques to create a tailored path for your needs."
          buttons={[
            {
              text: 'Disability Specialist Services',
              to: '/services/positive-behaviour-support'
            },
            {
              text: 'Supervision',
              to: '/services/supervision'
            },
            {
              text: 'Mental Health Services',
              to: '/services/mental-health-services'
            }
          ]}
        />
        <ContentCard
          image={data.faceCloseUp.childImageSharp}
          title="Agency Services"
          text="Rob provides training to carers, agencies, groups and individuals in the areas of disability and trauma. Contact Rob to find more about our specialised agency services."
          buttons={[
            {
              text: 'Agency Specialist Services',
              to: '/services/training-agency-specialist-services'
            }
          ]}
        />
      </AlternatingContent>

      <div style={{ marginBottom: 30 }}>
        <CTA
          inverted={true}
          title="Looking for psychological support?"
          subtitle="Rob's here to help you."
          button="Contact Rob Today"
        />
      </div>

      {/* <Flex mt={4} mb={5} flexDirection="column" justifyContent="center">
        <h2
          style={{ textAlign: 'center', marginBottom: '1em', fontWeight: 400 }}
        >
          Why Upright Performance
        </h2>

        <CardsContainer>
          <Cards>
            <Card
              color="tertiary"
              shadow={true}
              title="Trusted"
              content="We have a long list of clients delighted with our personalised service and care. "
            />

            <Card
              color="primary"
              shadow={true}
              title="Skilled"
              content="You know you are in the safest hands with a Psychologist who is experienced. With over 13 years in public and private practice. Rob Morgan has worked with hundreds of clients in a wide range of settings."
            />

            <Card
              color="purple"
              shadow={true}
              title="Effective"
              content="Of course healing is our top priority and so we are continually learning new and evolving research based techniques and practices to more effectively get the results you are looking for."
            />
          </Cards>
        </CardsContainer>
      </Flex> */}

      <Flex id="team" justifyContent="center" mb={4} mt={6}>
        <h1>Meet Rob</h1>
      </Flex>
      <Container
        style={{ borderRadius: 4, marginBottom: 50 }}
        bg="rgb(241, 248, 254)"
        padY={true}
        medium={true}
      >
        <SplitSection style={{ marginLeft: 0 }}>
          <SplitFigure>
            <Img
              fluid={data.robSitting.childImageSharp.fluid}
              alt="Turboclicks business woman smiling"
            />
          </SplitFigure>
          <SplitContent>
            <h5>Looking for a psychologist?</h5>
            <p>
              I have an ongoing commitment to professional development to ensure
              evidenced-based best practice.
            </p>
            <p>
              Send Rob a message or give him a call to find out how we can help
              you today.
            </p>
            <ReadMore to="/contact">
              Contact Rob
              <Arrow rotate="-90deg" />
            </ReadMore>
          </SplitContent>
        </SplitSection>
        <Flex justifyContent="center" mt={5}>
          <h3>
            "I see a need for more focus on adolescent and adult male mental
            health services and am looking to grow in this direction." - Rob
            Morgan
          </h3>
        </Flex>
      </Container>

      <AboutContact data={data} noContact={true} noBorder={true} />

      <Map style={{ marginBottom: 0 }} />

      <VideoContainer style={{ marginTop: 30 }}>
        <article>
          <p>
            <i>Book an appointment with Rob.</i>
          </p>

          <p>
            Rob's evidence based approach, in partnering with you, aims to
            support you with a range of disability and mental health challenges
            including:
          </p>
          <ul>
            <li>specialist behaviour support</li>
            <li>restricted practices</li>
            <li>depression and anxiety</li>
            <li>feel lost and anxious</li>
            <li>anger</li>
            <li>grief and loss</li>
            <li>stress and change management</li>
            <li>training and supervision</li>
            <li>agency specialist services</li>
            <li>and so much more...</li>
          </ul>

          <p>
            Rob is available to discuss your individual situation and partner
            with you in tailoring an intervention to meet your needs. Contact
            Rob today.
          </p>
          <Flex>
            <Link to="/contact">
              <Button color={'#011a67'} small={true} outline={true}>
                Contact Rob Today
              </Button>
            </Link>
          </Flex>
        </article>
      </VideoContainer>

      <Contact noBorder={true} />
    </Layout>
  )
}

export const query = graphql`
  query HomeQuery {
    womanWaterfall: file(relativePath: { eq: "rob-homepage-top.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    faceCloseUp: file(relativePath: { eq: "agency.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    woodsWoman: file(relativePath: { eq: "psychology-services.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    drSellars: file(relativePath: { eq: "camping.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    robSitting: file(relativePath: { eq: "rob-pose.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cosmetic: file(relativePath: { eq: "homepage-image.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    nonSurgical: file(relativePath: { eq: "non-surgical-woman.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
